.dropdown-item a {
	color: inherit;
	text-decoration: none;
}


.no-caret {
	.dropdown-toggle::after {
		display: none !important;
	}
}

.dropdown-item.active, .dropdown-item:active {
	background-color: #e9ecef;
}